import { isRouteErrorResponse, Link } from '@remix-run/react'
import { Fragment } from 'react'
import {
  BackgroundElements,
  PageLayoutWithoutHeader,
} from '~/components/Layout'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import classNames from 'classnames'
import { focusRing } from '~/utils/styles'
import { ROUTES } from '~/routes'
interface ErrorPageProps {
  error: unknown
}

const strings = {
  unexpectedError: 'Unexpected error',
  homepage: 'Startseite',
}

const ErrorDisplay = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      style={{ fontFamily: 'monospace' }}
      className='tracked-m flex max-w-full flex-col items-center gap-2 rounded-lg border-2 border-lavender-500 bg-white px-3 py-2 text-xs text-lavender-700 shadow-lg'
    >
      {children}
    </div>
  )
}

const ErrorTitle = (props: { status: number; statusText: string }) => {
  return (
    <div className='flex items-center text-h6 tracking-wider text-grey-700'>
      <span className='border-r border-grey-300 pe-4'>{props.status}</span>
      <span className='ps-4'>{props.statusText}</span>
    </div>
  )
}

const Container = ({ children }: { children: React.ReactNode }) => {
  return (
    <PageLayoutWithoutHeader background={<BackgroundElements />}>
      <div className={'-inset-0 -z-20 bg-grey-200'} />
      <div className='flex min-h-screen max-w-full items-center justify-center'>
        <div className='flex max-w-full flex-col items-center gap-5 p-4 text-grey-800 md:p-8'>
          {children}
        </div>
      </div>
    </PageLayoutWithoutHeader>
  )
}

export const ErrorPage = ({ error }: ErrorPageProps) => {
  if (isRouteErrorResponse(error)) {
    const data = error.data

    const isDataString = data && typeof data === 'string'
    const dataKeys = data ? Object.keys(data) : []

    return (
      <Container>
        <ErrorTitle status={error.status} statusText={error.statusText} />
        <Link
          to={ROUTES.CHOOSE_STORE.path}
          className={classNames(focusRing, 'underline')}
        >
          {strings.homepage}
        </Link>

        {isDataString ? (
          <ErrorDisplay>{data}</ErrorDisplay>
        ) : (
          dataKeys.length > 0 && (
            <ErrorDisplay>
              {dataKeys.map(key => (
                <Fragment key={key}>
                  {key}: {data[key]}
                  <br />
                </Fragment>
              ))}
            </ErrorDisplay>
          )
        )}
      </Container>
    )
  }

  return (
    <Container>
      <ErrorTitle status={500} statusText={strings.unexpectedError} />
      <Link
        to={ROUTES.CHOOSE_STORE.path}
        className={classNames(focusRing, 'underline')}
      >
        {strings.homepage}
      </Link>
      {error instanceof Error ? (
        <Disclosure>
          {({ open }) => (
            <ErrorDisplay>
              {error.message || 'No error message'}
              <DisclosurePanel className='max-w-full'>
                <pre className='max-w-full whitespace-pre-wrap break-all'>
                  {error.stack}
                </pre>
              </DisclosurePanel>
              <DisclosureButton
                className={classNames(
                  focusRing,
                  'text-[0.5rem] font-semibold underline hover:no-underline',
                )}
              >
                {open ? 'Hide full stack' : 'Show full stack'}
              </DisclosureButton>
            </ErrorDisplay>
          )}
        </Disclosure>
      ) : (
        <ErrorDisplay>{JSON.stringify(error, null, 2)}</ErrorDisplay>
      )}
    </Container>
  )
}
