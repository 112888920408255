import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  ShouldRevalidateFunction,
  useLoaderData,
  useRouteError,
} from '@remix-run/react'
import { LinksFunction, MetaFunction } from '@remix-run/node'
import type { LoaderFunction } from '@remix-run/router'
import { getAuthStateFromSession } from '~/auth/session.server'
import { AuthState } from '~/auth/AuthState'
import { AuthProvider } from '~/auth/AuthProvider'
import { faviconLinks } from './faviconLinks'

// the ?url is required for some reason when using vite
// https://remix.run/docs/en/main/styling/css
import stylesheet from '~/tailwind.css?url'
import { PageLoadingProgress } from '~/components/PageLoadingProgress'
import { useNonce } from '~/utils/nonce-provider'
import { setupMobiscroll } from '~/config/mobiscroll'
import { ErrorPage } from '~/components/ErrorPage'
import { variables, VariablesServer } from '~/config/variables.server'
import { VariablesClient } from '~/config/variables.client'
import {
  GoogleTagManagerNoscript,
  GoogleTagManagerScript,
} from '~/utils/googleAnalytics'

setupMobiscroll()

export const meta: MetaFunction = () => [
  { charset: 'utf-8' },
  { title: 'Joviva admin' },
  { name: 'viewport', content: 'width=device-width,initial-scale=1' },
]

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: stylesheet },
  ...faviconLinks,
]

declare global {
  interface Window {
    ENV: VariablesClient | undefined
    dataLayer: unknown[] | undefined
  }
}

type LoaderReturnType = {
  ENV: Pick<
    VariablesServer,
    | 'NODE_ENV'
    | 'SENTRY_DSN'
    | 'SENTRY_ENVIRONMENT'
    | 'SENTRY_RELEASE'
    | 'DEACTIVATED_FEATURES'
    | 'GOOGLE_TAG_MANAGER_ID'
  >
  authState: AuthState
}
export const loader: LoaderFunction = async ({ request }) => {
  const authState = await getAuthStateFromSession(request)

  return Response.json({
    authState: authState,
    // expose SOME server variables to the client
    ENV: {
      NODE_ENV: variables.NODE_ENV,
      SENTRY_DSN: variables.SENTRY_DSN,
      SENTRY_ENVIRONMENT: variables.SENTRY_ENVIRONMENT,
      SENTRY_RELEASE: process.env.SENTRY_RELEASE,
      DEACTIVATED_FEATURES: variables.DEACTIVATED_FEATURES,
      GOOGLE_TAG_MANAGER_ID: variables.GOOGLE_TAG_MANAGER_ID,
    },
  } satisfies LoaderReturnType)
}

export const shouldRevalidate: ShouldRevalidateFunction = () => {
  return false
}

const Document = ({
  children,
  nonce,
  gtmId,
}: {
  children: React.ReactNode
  nonce: string
  gtmId: string | undefined
}) => {
  return (
    <html lang={'de'}>
      <head>
        <Meta />
        <Links />
        {gtmId && <GoogleTagManagerScript gtmId={gtmId} nonce={nonce} />}
      </head>
      <body className='text-default'>
        <PageLoadingProgress />
        {children}
        {/* Manages scroll position for client-side transitions */}
        {/* If you use a nonce-based content security policy for scripts, you must provide the `nonce` prop. Otherwise, omit the nonce prop as shown here. */}
        <ScrollRestoration nonce={nonce} />

        {/* Script tags go here */}
        {/* If you use a nonce-based content security policy for scripts, you must provide the `nonce` prop. Otherwise, omit the nonce prop as shown here. */}
        <Scripts nonce={nonce} />

        {gtmId && <GoogleTagManagerNoscript gtmId={gtmId} />}
      </body>
    </html>
  )
}

export const ErrorBoundary = () => {
  const error = useRouteError()
  const nonce = useNonce()

  console.error('Root ErrorBoundary caught route error ', error)

  return (
    <Document
      nonce={nonce}
      // the GA_TRACKING_ID is exposed by the root loader.
      // Therefore, we cannot ensure its presence in the ErrorBoundary
      gtmId={undefined}
    >
      <ErrorPage error={error} />
    </Document>
  )
}

export default function App() {
  const { ENV, authState } = useLoaderData<LoaderReturnType>()
  const nonce = useNonce()

  return (
    <Document nonce={nonce} gtmId={ENV.GOOGLE_TAG_MANAGER_ID}>
      {/* Child routes render here */}
      <AuthProvider initialState={authState}>
        <Outlet />
      </AuthProvider>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.ENV = ${JSON.stringify(ENV)}`,
        }}
        nonce={nonce}
      />
    </Document>
  )
}
